import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { tap, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';

import { HttpService } from 'src/app/services/http.service';
import { getParameterByName } from 'src/app/helpers/price-options.helpers';

@Component({
    templateUrl: './settings.component.html',
    standalone: false
})
export class SettingsComponent implements OnInit, OnDestroy {
    @ViewChild('copyEl', { static: true }) copyRef: ElementRef;

    @Output() formSubmit: EventEmitter<{ [key: string]: string }> = new EventEmitter();

    public faCopy = faCopy;

    public form: UntypedFormGroup;
    public languages: any[] = [];
    public venues: any[] = [];
    public activities: any[] = [];
    public brands: any[] = [];
    public packages: any[] = [];
    public sessions: any[] = [];
    public example = '';

    private directories: any;
    private formSub: Subscription;

    constructor(
        private builder: UntypedFormBuilder,
        private http: HttpService
    ) { }

    ngOnInit() {
        this.form = this.builder.group({
            venueId: ['1003398'],
            activityId: ['208'],
            packageId: [null],
            sessionId: [null],
            brandId: ['4696'],
            baseUrl: ['https://geronigo.gitlab.io/bookit-light/#/'],
            dateStr: [''],
            lang: ['EN'],
            primaryColor: ['#cc0000'],
            accentColor: ['#4a5568'] ,
            textColor: ['#000000'],
            textColor2: ['#000000'],
            background: ['#ffffff'],
            voucher: [true],
            inline: [true]
        });

        this.formSub = this.form.valueChanges.pipe(startWith(this.form.getRawValue())).subscribe(() => this.setExample());

        this.getData(true);
    }

    ngOnDestroy() {
        if (this.formSub) this.formSub.unsubscribe();
    }

    public updateBookit(): void {
        const form = this.form.getRawValue();

        this.formSubmit.emit({ ...form, voucher: form.voucher ? '0' : '1', inline: form.inline ? '1' : '0' });
    }

    public copyText(): void {
        const textarea: HTMLTextAreaElement = this.copyRef.nativeElement;

        textarea.select();
        document.execCommand('copy');
    }

    public setVenue(venue: any, reset = true): void {
        const value = { ...this.form.getRawValue() };

        if (reset) {
            value.activityId = null;
            value.packageId = null;
            value.sessionId = null;
        }

        if (venue) {
            value.venueId = venue.venue_id;
            value.activityId = venue.activity_ids[0];
        }

        this.form.setValue(value);

        this.activities = this.directories.activities.filter(item => venue.activity_ids.includes(item.activity_id));

        this.getPackages();
    }

    public getPackages(): void {
        const { activityId, venueId } = this.form.getRawValue();

        if (!activityId || !venueId) return;

        this.http.get('/packages', {
            params: {
                extra: '0',
                active: '1',
                bookeable: '1',
                activity_id: activityId,
                venue_id: venueId
            },
            headers: {
                'X-ATTRIBUTES': 'package_id,name'
            }
        }).subscribe(res => {
            this.packages = res.body.packages;
        });
    }

    private getData(init = false): void {
        const params: any = { directories: 'venues,activities,brands,languages' };
        const companyId = getParameterByName('company_id');

        if (companyId) params.company_id = companyId;

        this.http.get('/directories', { params }).pipe(
            tap(res => {
                this.directories = res.body;

                this.brands = res.body.brands;
                this.venues = res.body.venues;
                this.languages = res.body.languages;

                const venue = this.venues.find(item => item.venue_id === '1003398') || this.venues[0];

                this.setVenue(venue, false);

                if (init) this.updateBookit();
            })
        ).subscribe();
    }

    private setExample(): void {
        const form = this.form.getRawValue();

        let example = '<geronigo-bookit\n\t';

        example += `venue-id="${form.venueId}"\r\n\t`;
        example += `activity-id="${form.activityId}"\n\t`;
        example += `brand-id="${form.brandId}"\n\t`;
        example += `package-id="${form.packageId}"\n\t`;
        example += `session-id="${form.sessionId}"\n\t`;
        example += `date-str="${form.dateStr}"\n\t`;
        example += `base-url="${form.baseUrl}"\n\t`;
        example += `primary-color="${form.primaryColor}"\n\t`;
        example += `accent-color="${form.accentColor}"\n\t`;
        example += `mode="test"\n\t`;
        example += `origin-app=""\n\t`;
        example += `origin-cta=""\n\t`;
        example += `app-key="geronigobookit"\n\t`;
        example += `api-key="rezbotbookit"\n\t`;
        example += `voucher="${form.voucher ? '1' : '0'}"\n\t`;
        example += `inline="${form.inline ? '1' : '0'}"\n\t`;
        example += `form-settings=""\n`;
        example += `></geronigo-bookit>`;

        this.example = example;
    }
}
