@if (!fieldsLoading) {
  <form #customForm="ngForm" (ngSubmit)="saveFields()">
    @for (item of participantsFields; track item; let isLast = $last; let i = $index) {
      <div
        class="gn-w-full  md:gn-w-80 md:gn-inline-block md:gn-mx-4
        gn-bg-white gn-rounded gn-border gn-border-gray-500 gn-p-2 gn-cursor-pointer"
        [ngClass]="isLast ? 'gn-mb-4' : 'gn-mb-2'"
        (click)="fillFields(item, i)"
        >
        <div class="gn-flex gn-items-center">
          <p class="gn-text-sm gn-font-medium gn-flex-1 gn-text-gray-700">{{ item.title }}</p>
          <p class="gn-text-sm gn-font-medium gn-flex-1" [ngClass]="item.submitted ? 'gn-text-gray-500' : (!item?.error && item.completed ? 'gn-text-green-500' : 'gn-text-red-500')">{{ item.error ? 'Error. Check value and resubmit' : (item.submitted ? 'Submitted': (item.completed ? 'Complete' : 'Extra Information Required')) }}</p>
          <div class="gn-flex-1 gn-flex gn-justify-end">
            <a href="javascript:void(0)" class="gn-block gn-px-2 gn-py-1 gn-bg-green-500 gn-text-white gn-text-sm gn-font-medium gn-rounded">
              Edit
            </a>
          </div>
        </div>
        @if (participant && participantIndex === i) {
          <form class="gn-mt-4 gn-text-gray-800" #participantForm="ngForm" (ngSubmit)="saveParticipantFields()">
            <div class="gn-w-full gn-flex md:gn-flex-row gn-flex-col md:gn-flex-wrap">
              @for (field of participant.fields; track field; let fieldIndex = $index) {
                <div class="gn-w-full md:gn-w-1/2" [ngClass]="fieldIndex % 2 === 0 ? 'md:gn-pr-1' : 'md:gn-pl-1'">
                  @switch (field.type) {
                    @case ('bool') {
                      <label class="gn-font-medium gn-text-sm gn-text-gray-700">
                        {{ field.name }} <input type="checkbox" [(ngModel)]="field.value" [name]="field.inputName + '_' + fieldIndex" (change)="item.completed = false; item.submitted=false; item.error=false;"/>
                      </label>
                    }
                    @case ('boolean') {
                      <label class="gn-font-medium gn-text-sm gn-text-gray-700">
                        {{ field.name }} <input type="checkbox" [(ngModel)]="field.value" [name]="field.inputName + '_' + fieldIndex" (change)="item.completed = false; item.submitted=false; item.error=false;"/>
                      </label>
                    }
                    @case ('list') {
                      <div class="gn-w-full gn-flex gn-flex-col gn-items-start">
                        <label class="gn-font-medium gn-text-sm gn-text-gray-700">{{ field.name }}</label>
                        <ng-select class="gn-w-100" [items]="field.opt" [name]="field.inputName + '_' + fieldIndex" [(ngModel)]="field.value"  (change)="item.completed = false; item.submitted=false; item.error=false;" required></ng-select>
                      </div>
                    }
                    @case ('date') {
                      <div class="gn-w-full gn-flex gn-flex-col gn-items-start">
                        <label class="gn-font-medium gn-text-sm gn-text-gray-700">{{ field.name }}</label>
                        <input (focus)="pickerEl.open()" class="gn-bg-white gn-p-2 gn-border gn-border-gray-400 gn-w-full gn-rounded" [matDatepicker]="pickerEl" [name]="field.inputName + '_' + fieldIndex" [(ngModel)]="field.value" (change)="item.completed = false; item.submitted=false; item.error=false;" readonly required>
                        <mat-datepicker #pickerEl></mat-datepicker>
                      </div>
                    }
                    @default {
                      <div class="gn-w-full gn-flex gn-flex-col gn-items-start">
                        <label class="gn-font-medium gn-text-sm gn-text-gray-700">{{ field.name }}</label>
                        <input class="gn-bg-white gn-p-2 gn-border gn-border-gray-400 gn-w-full gn-rounded" [(ngModel)]="field.value" [name]="field.inputName + '_' + fieldIndex"  [type]="field.type" (change)="item.completed = false; item.submitted=false; item.error=false;" required />
                      </div>
                    }
                  }
                </div>
              }
            </div>
            <div class="gn-w-full gn-text-right gn-mt-4">
              <button class="gn-uppercase gn-rounded gn-text-md gn-font-semibold gn-px-8 gn-py-3 hover:gn-opacity-75" [style.background]="primaryColor" [disabled]="participantForm.invalid" type="submit">
                <span class="gn-text">Continue</span>
                <span class="gn-hint">{{ participantHint }}</span>
              </button>
            </div>
          </form>
        }
      </div>
    }
    @if (bookingFields.length) {
      <div class="gn-w-full gn-flex md:gn-flex-row gn-flex-col md:gn-flex-wrap">
        @for (field of bookingFields; track field; let fieldIndex = $index) {
          <div class="gn-w-full md:gn-w-1/2" [ngClass]="fieldIndex % 2 === 0 ? 'md:gn-pr-1' : 'md:gn-pl-1'">
            @switch (field.type) {
              @case ('bool') {
                <label class="gn-font-medium gn-text-sm gn-text-gray-700">
                  {{ field.name }} <input type="checkbox" [(ngModel)]="field.value" [name]="field.inputName + '_' + fieldIndex" />
                </label>
              }
              @case ('boolean') {
                <label class="gn-font-medium gn-text-sm gn-text-gray-700">
                  {{ field.name }} <input type="checkbox" [(ngModel)]="field.value" [name]="field.inputName + '_' + fieldIndex" />
                </label>
              }
              @case ('list') {
                <div class="gn-w-full gn-flex gn-flex-col gn-items-start">
                  <label class="gn-font-medium gn-text-sm gn-text-gray-700">{{ field.name }}</label>
                  <ng-select class="gn-w-100" [items]="field.opt" [name]="field.inputName + '_' + fieldIndex" [(ngModel)]="field.value" required></ng-select>
                </div>
              }
              @case ('date') {
                <div class="gn-w-full gn-flex gn-flex-col gn-items-start">
                  <label class="gn-font-medium gn-text-sm gn-text-gray-700">{{ field.name }}</label>
                  <input (focus)="pickerEl.open()" class="gn-bg-white gn-p-2 gn-border gn-border-gray-400 gn-w-full gn-rounded" [matDatepicker]="pickerEl" [name]="field.inputName + '_' + fieldIndex" [(ngModel)]="field.value" readonly required>
                  <mat-datepicker #pickerEl></mat-datepicker>
                </div>
              }
              @default {
                <div class="gn-w-full gn-flex gn-flex-col gn-items-start">
                  <label class="gn-font-medium gn-text-sm gn-text-gray-700">{{ field.name }}</label>
                  <input class="gn-bg-white gn-p-2 gn-border gn-border-gray-400 gn-w-full gn-rounded" [(ngModel)]="field.value" [name]="field.inputName + '_' + fieldIndex"  [type]="field.type||'text'" required />
                </div>
              }
            }
          </div>
        }
      </div>
    }
    <div class="gn-w-full gn-mt-4 gn-flex gn-items-center gn-justify-end">
      @if (!success) {
        <button class="gn-uppercase gn-rounded gn-text-md gn-font-semibold gn-px-8 gn-py-3 hover:gn-opacity-75" [style.background]="primaryColor" [style.color]="textColor" [disabled]="customForm.invalid || !participantsCompleted" type="submit">
          @if (!loading) {
            <span class="gn-text">Save</span>
          }
          @if (!loading) {
            <span class="gn-hint">{{ hint }}</span>
          }
          @if (loading) {
            <div class="gn-spinner-loader gn-spinner-inverse">
              <div class="gn-bounce1"></div>
              <div class="gn-bounce2"></div>
              <div class="gn-bounce3"></div>
            </div>
          }
        </button>
      }
    </div>
  </form>
}
@if (fieldsLoading) {
  <div class="gn-flex gn-items-center gn-justify-center">
    <div class="gn-w-32 gn-h-32 gn-mx-auto gn-rounded-full gn-border-t-2 gn-border-r-2 gn-border-solid gn-spin" [style.border-color]="primaryColor"></div>
  </div>
}
@if (success) {
  <div class="gn-flex gn-items-center gn-justify-center">
    <div class="" [style.border-color]="primaryColor">{{success}}</div>
  </div>
}